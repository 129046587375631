<template>
  <div class="bg-white rounded-lg shadow p-6 mb-6">
    <h3 class="text-xl font-semibold text-gray-900 mb-4">
      {{ isEditing ? 'Edit Task' : 'Create New Task' }}
    </h3>
    <form @submit.prevent="submitTask" class="space-y-4">
      <div>
        <label for="title" class="form-label">Title:</label>
        <input type="text" id="title" v-model="task.title" required class="form-input">
      </div>
      <div>
        <label for="description" class="form-label">Description:</label>
        <textarea id="description" 
                  v-model="task.description" 
                  class="form-input min-h-[100px]"
                  rows="4"></textarea>
      </div>
      <div class="flex justify-end space-x-3 pt-4">
        <button type="button" @click="cancel" class="btn btn-secondary">
          Cancel
        </button>
        <button type="submit" class="btn btn-primary">
          {{ isEditing ? 'Update' : 'Create' }} Task
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import api from '@/services/api';

export default {
  props: {
    editTask: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      task: {
        title: '',
        description: ''
      },
      isEditing: false
    };
  },
  watch: {
    editTask: {
      handler(newTask) {
        if (newTask) {
          this.task = { ...newTask };
          this.isEditing = true;
        } else {
          this.resetForm();
        }
      },
      immediate: true
    }
  },
  methods: {
    async submitTask() {
      try {
        const taskData = {
          name: this.task.title,
          description: this.task.description,
          dueDateUtc: null
        };

        if (this.isEditing) {
          console.warn('Update endpoint not available');
        } else {
          await api.post('/tasks/create', taskData);
        }
        this.$emit('task-submitted');
        this.resetForm();
      } catch (error) {
        console.error('Error submitting task:', error);
      }
    },
    resetForm() {
      this.task = { title: '', description: '' };
      this.isEditing = false;
    },
    cancel() {
      this.$emit('cancel');
      this.resetForm();
    }
  }
};
</script>
