import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import LandingPage from './views/LandingPage.vue'
import Tasks from './views/Tasks.vue'
import LoginForm from './components/auth/LoginForm.vue'
import RegisterForm from './components/auth/RegisterForm.vue'
import authService from './services/auth'

// Create router
const router = createRouter({
  history: createWebHistory(),
  routes: [
    { 
      path: '/', 
      name: 'home',
      component: LandingPage,
      meta: { requiresAuth: false }
    },
    { 
      path: '/tasks', 
      name: 'tasks', 
      component: Tasks, 
      meta: { requiresAuth: true } 
    },
    { 
      path: '/login', 
      name: 'login', 
      component: LoginForm, 
      meta: { guestOnly: true } 
    },
    { 
      path: '/register', 
      name: 'register', 
      component: RegisterForm, 
      meta: { guestOnly: true } 
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      redirect: '/'
    }
  ]
})

// Add global error handler
router.onError((error) => {
  console.error('Router error:', error)
  router.push('/')
})

// Navigation guard
router.beforeEach((to, from, next) => {
  // Verify route exists
  if (!to.name) {
    return next({ name: 'home' })
  }

  const isAuthenticated = authService.isAuthenticated()

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      return next({ name: 'login' })
    }
  } else if (to.matched.some(record => record.meta.guestOnly)) {
    if (isAuthenticated) {
      return next({ name: 'tasks' })
    }
  }

  next()
})

// Create and mount the Vue application
const app = createApp(App)
app.use(router)

// Wait for router to be ready before mounting
console.log('Waiting for router to be ready...')
router.isReady()
  .then(() => {
    console.log('Router is ready, mounting app...')
    app.mount('#app')
  })
  .catch(error => {
    console.error('Failed to initialize router:', error)
    // Attempt to mount anyway as fallback
    console.log('Attempting to mount app despite router error...')
    app.mount('#app')
  })
