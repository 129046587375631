<template>
  <div class="space-y-16">
    <div class="text-center">
      <h1 class="text-4xl font-bold text-gray-900 mb-4">Welcome to ManagerCopilot</h1>
      <p class="text-xl text-gray-600">Your AI-powered assistant for engineering managers</p>
    </div>
    
    <section class="max-w-4xl mx-auto">
      <h2 class="text-2xl font-bold text-gray-900 mb-6">Features</h2>
      <ul class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <li v-for="feature in features" :key="feature" 
            class="flex items-center p-4 bg-white rounded-lg shadow-sm">
          <svg class="w-6 h-6 text-primary-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
          {{ feature }}
        </li>
      </ul>
    </section>
    
    <section class="bg-gray-50 py-12 px-4">
      <h2 class="text-2xl font-bold text-gray-900 mb-8 text-center">Pricing</h2>
      <div class="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
        <div v-for="plan in plans" :key="plan.name" 
             class="bg-white rounded-lg shadow-lg p-8">
          <h3 class="text-xl font-bold text-gray-900 mb-2">{{ plan.name }}</h3>
          <p class="text-3xl font-bold text-primary-600 mb-6">${{ plan.price }}/month</p>
          <ul class="space-y-3">
            <li v-for="feature in plan.features" :key="feature" 
                class="flex items-start">
              <svg class="w-5 h-5 text-green-500 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg>
              {{ feature }}
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import authService from '@/services/auth';

export default {
  name: 'LandingPage',
  setup() {
    const isAuthenticated = ref(false);

    onMounted(() => {
      isAuthenticated.value = authService.isAuthenticated();
    });

    return {
      isAuthenticated,
      features: [
        'AI-driven task prioritization',
        'Team performance analytics',
        'Automated meeting scheduling',
        'Decision support system'
      ],
      plans: [
        {
          name: 'Starter',
          price: 49,
          features: [
            'Up to 5 team members',
            'Basic analytics',
            'Email support'
          ]
        },
        {
          name: 'Pro',
          price: 99,
          features: [
            'Up to 15 team members',
            'Advanced analytics',
            'Priority support'
          ]
        }
      ]
    };
  }
};
</script>
