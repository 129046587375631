<template>
  <div class="space-y-6">
    <div class="flex justify-between items-center">
      <h1 class="text-3xl font-bold text-gray-900">Tasks</h1>
      <button @click="showForm = true" 
              class="btn btn-primary">
        Create New Task
      </button>
    </div>

    <TaskForm
      v-if="showForm"
      :editTask="editingTask"
      @task-submitted="handleTaskSubmitted"
      @cancel="handleCancel"
    />

    <TaskList
      @edit-task="handleEditTask"
      :key="listKey"
    />
  </div>
</template>

<script>
import TaskForm from '@/components/tasks/TaskForm.vue';
import TaskList from '@/components/tasks/TaskList.vue';

export default {
  name: 'TasksPage',
  components: {
    TaskForm,
    TaskList
  },
  data() {
    return {
      showForm: false,
      listKey: 0,
      editingTask: null
    };
  },
  methods: {
    handleTaskSubmitted() {
      this.showForm = false;
      this.editingTask = null;
      this.listKey += 1; // Force TaskList to re-render
    },
    handleEditTask(task) {
      this.editingTask = task;
      this.showForm = true;
    },
    handleCancel() {
      this.showForm = false;
      this.editingTask = null;
    }
  }
};
</script>
