<template>
  <div class="max-w-md mx-auto bg-white rounded-lg shadow-md p-8">
    <h2 class="text-2xl font-bold text-gray-900 mb-6">Register</h2>
    <form @submit.prevent="register" class="space-y-6">
      <div>
        <label for="email" class="form-label">Email:</label>
        <input type="email" id="email" v-model="email" required class="form-input">
      </div>
      <div>
        <label for="firstName" class="form-label">First Name:</label>
        <input type="text" id="firstName" v-model="firstName" required class="form-input">
      </div>
      <div>
        <label for="lastName" class="form-label">Last Name:</label>
        <input type="text" id="lastName" v-model="lastName" required class="form-input">
      </div>
      <div>
        <label for="password" class="form-label">Password:</label>
        <input type="password" id="password" v-model="password" required class="form-input">
      </div>
      <div>
        <label for="confirmPassword" class="form-label">Confirm Password:</label>
        <input type="password" id="confirmPassword" v-model="confirmPassword" required class="form-input">
      </div>
      <div>
        <label for="companyName" class="form-label">Company Name:</label>
        <input 
          type="text" 
          id="companyName" 
          v-model.trim="companyName" 
          required 
          class="form-input">
      </div>
      <div>
        <label for="companySize" class="form-label">Company Size:</label>
        <select id="companySize" v-model="companySize" required class="form-input">
          <option value="1">Micro (1-9 employees)</option>
          <option value="2">Small (10-49 employees)</option>
          <option value="3">Medium (50-249 employees)</option>
          <option value="4">Large (250-999 employees)</option>
          <option value="5">Enterprise (1000+ employees)</option>
        </select>
      </div>
      <button type="submit" class="w-full btn btn-primary">Register</button>
    </form>
  </div>
</template>

<script>
import authService from '@/services/auth';

export default {
  data() {
    return {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      companyName: '',
      companySize: ''
    };
  },
  methods: {
    async register() {
      if (this.password !== this.confirmPassword) {
        alert('Passwords do not match');
        return;
      }

      if (!this.companyName?.trim()) {
        alert('Company name is required');
        return;
      }

      try {
        await authService.register(
          this.email.trim(),
          this.password,
          this.firstName.trim(),
          this.lastName.trim(),
          this.companyName.trim(),
          parseInt(this.companySize)
        );
        this.$router.push('/login');
        alert('Registration successful! Please login.');
      } catch (error) {
        console.error('Registration failed:', error);
        alert(error.response?.data?.message || 'Registration failed. Please try again.');
      }
    }
  }
};
</script>

