<template>
  <div class="max-w-md mx-auto bg-white rounded-lg shadow-md p-8" v-if="!isAuthenticated">
    <h2 class="text-2xl font-bold text-gray-900 mb-6">Login</h2>
    <form @submit.prevent="login" class="space-y-6">
      <div>
        <label for="email" class="form-label">Email:</label>
        <input type="email" id="email" v-model="email" required class="form-input">
      </div>
      <div>
        <label for="password" class="form-label">Password:</label>
        <input type="password" id="password" v-model="password" required class="form-input">
      </div>
      <button type="submit" class="w-full btn btn-primary">Login</button>
      <p v-if="error" class="mt-2 text-sm text-red-600">{{ error }}</p>
      <p v-if="successMessage" class="mt-2 text-sm text-green-600">{{ successMessage }}</p>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import authService from '@/services/auth';

export default {
  setup() {
    const router = useRouter();
    const email = ref('');
    const password = ref('');
    const error = ref(null);
    const successMessage = ref(null);
    const isAuthenticated = ref(false);

    const checkAuthentication = () => {
      isAuthenticated.value = authService.isAuthenticated();
      if (isAuthenticated.value) {
        router.push({ name: 'home' });
      }
    };

    const login = async () => {
      error.value = null;
      successMessage.value = null;
      try {
        const response = await authService.login(email.value, password.value);
        console.log('Login response:', response);
        if (response) {
          successMessage.value = 'Login successful!';
          setTimeout(() => {
            router.push({ name: 'home' });
          }, 500);
        } else {
          error.value = 'Login failed. Please check your credentials.';
        }
      } catch (err) {
        console.error('Login error:', err);
        if (err.response) {
          console.error('Error response:', err.response.data);
          console.error('Error status:', err.response.status);
        }
        error.value = err.response?.data?.message || 'An error occurred during login. Please try again.';
      }
    };

    checkAuthentication();

    return {
      email,
      password,
      error,
      successMessage,
      isAuthenticated,
      login
    };
  }
};
</script>

<style scoped>
.error {
  color: red;
}
.success {
  color: green;
}
</style>
