import axios from 'axios';
import { reactive } from 'vue';

const API_URL = process.env.VUE_APP_API_URL;
const AUTH_URL = `${API_URL}users/`;

const state = reactive({
  isAuthenticated: false
});

export default {
  async login(email, password) {
    try {
      const response = await axios.post(`${AUTH_URL}login`, { email, password });
      console.log('API response:', response.data);
      if (response.data.accessToken) {
        localStorage.setItem('accessToken', response.data.accessToken);
        state.isAuthenticated = true;
      }
      return response.data;
    } catch (error) {
      console.error('API error:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  logout() {
    localStorage.removeItem('accessToken');
    state.isAuthenticated = false;
  },

  getToken() {
    return localStorage.getItem('accessToken');
  },

  isAuthenticated() {
    state.isAuthenticated = !!this.getToken();
    return state.isAuthenticated;
  },

  getAuthState() {
    return state;
  },

  async register(email, password, firstName, lastName, companyName, companySize) {
    try {
      const response = await axios.post(`${AUTH_URL}register`, {
        email,
        password,
        firstName,
        lastName,
        companyName,
        companySize
      });
      console.log('API response:', response.data);
      return response.data;
    } catch (error) {
      console.error('API error:', error.response ? error.response.data : error.message);
      throw error;
    }
  }
};
